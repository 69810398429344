<template>
  <div class="mobile_upload_way">
    <section class="modal_top">
      <div class="close_btn" @click="$emit('close')">
        <img src="@/assets/img/close-black.png" alt="닫기" />
      </div>
      <p>축제 자료 등록 완료</p>
    </section>
    <section class="modal_bottom">
      <div>
        <p>축제 자료 등록이 완료되었습니다.</p>
        <p>업로드하신 자료는 관리자가 확인한 후 플랫폼 셋팅을 진행합니다.</p>
      </div>
      <div>
        <p>
          플랫폼 셋팅은
          <span class="modal_underline">약 2주가 소요</span>되며,<br />
          <span>MY스탬프 팝 > 결제내역/진행현황</span> 메뉴에서 진행상황을
          확인하실 수 있습니다.
        </p>
      </div>
      <div>
        <p>
          파일에 문제가 있거나 보완이 필요한 경우 <br />회원정보에 저장된
          전화번호 또는 이메일로 연락드리겠습니다.
        </p>
      </div>
      <div>
        <p>
          문의사항이 있는 경우 고객센터로 연락주시면 친절히 안내해드리겠습니다.
        </p>
      </div>
      <div class="email_chat">
        <p><span>이메일 : </span>festival@nextinnovation.kr</p>
        <p><span>채팅상담 : </span>우측 하단 문의하기 버튼 클릭</p>
      </div>
      <div class="btn_center">
        <base-button type="primary" @click="$emit('close')">확인</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/desktop.scss";
</style>
