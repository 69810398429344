<template>
  <main class="reference">
    <div class="reference_wrapper">
      <section class="section_header">
        <h1>축제 자료 등록</h1>
        <div class="product_header_contents">
          <div>
            <span>1</span>
            <p></p>
          </div>
          <div>
            <span>2</span>
            <p>축제 자료 등록</p>
          </div>
          <div>
            <span>3</span>
            <p></p>
          </div>
        </div>
      </section>
      <section class="reference_body">
        <div class="preface">
          <div>
            <h3>축제정보</h3>
            <p>아래의 양식을 다운받아 작성한 후 파일을 업로드해주세요.</p>
          </div>
          <button type="button" @click="deskUploadWay">
            축제 자료를 지금 업로드 할 수 없다면?
            <img
              src="@/assets/img/rectangle-37.png"
              alt="축제 자료를 지금 업로드 할 수 없다면?"
            />
          </button>
          <button type="button" @click="mobileUploadWay">
            축제 자료를 지금 업로드 할 수 없다면?
            <img
              src="@/assets/img/rectangle-37-black.png"
              alt="축제 자료를 지금 업로드 할 수 없다면?"
            />
          </button>
        </div>
        <div class="download_box">
          <div class="title">
            <h3>양식 다운받기</h3>
            <a
              href="/docs/festival_info_v1.2.pdf"
              download="스탬프 팝 페스티벌_축제정보_작성예시"
              >작성예시 보기</a
            >
          </div>
          <div class="download_btns">
            <button
              type="button"
              @click.prevent.stop="
                download(
                  'docs/festival_info.doc',
                  '스탬프 팝 페스티벌_축제정보_양식',
                  'doc'
                )
              "
            >
              스탬프 팝 페스티벌_축제정보_양식.doc
            </button>
            <button
              type="button"
              @click.prevent.stop="
                download(
                  'docs/festival_info.hwp',
                  '스탬프 팝 페스티벌_축제정보_양식',
                  'hwp'
                )
              "
            >
              스탬프 팝 페스티벌_축제정보_양식.hwp
            </button>
          </div>
        </div>
        <div class="description">
          <h3>축제사진자료</h3>
          <div class="description_top">
            <p>- 축제 포스터 이미지 (1장)</p>
            <p>- 축제 소개 사진 (최대 4장)</p>
            <p>
              - 스탬프 제작용 참고 사진 (스탬프 개수에 맞춰 등록, 최대 10개)
            </p>
          </div>
          <div class="description_bottom">
            <p>※ 사진파일을 압축하여 하나의 파일로 업로드 해주세요.</p>
            <p>※ 스탬프 제작용 참고사진은 꼭 파일명에 장소명을 적어주세요.</p>
          </div>
        </div>
        <div class="upload_box">
          <h3>파일 업로드</h3>
          <div class="upload_contents">
            <div class="info">
              <h4>축제정보</h4>
              <input type="text" readonly v-model="info" />
              <input
                type="file"
                id="festival_info"
                name="festival_info"
                ref="refInfo"
                @change="previewFile('info')"
              />
              <label for="festival_info">찾아보기</label>
            </div>
            <div class="picture">
              <h4>사진자료</h4>
              <input type="text" readonly v-model="picture" />
              <input
                type="file"
                id="festival_picture"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="festival_picture">찾아보기</label>
            </div>
          </div>
          <p>※ 용량 10M 이하만 업로드 가능합니다.</p>
        </div>
        <div class="btn_center">
          <base-button
            type="primary"
            @click="deskOnSubmit"
            :disabled="submitStatus === 'PENDING'"
            >{{
              submitStatus === "PENDING" ? "등록중..." : "등록하기"
            }}</base-button
          >
          <base-button type="primary" @click="mobileOnSubmit"
            >등록하기</base-button
          >
        </div>
      </section>
    </div>
    <div class="mail_box">
      <div class="mail_box_contents">
        <div>
          <h3>파일 등록이 안되거나, 용량이 큰 경우 메일로 발송해주세요.</h3>
          <p>이메일 : festival@nextinnovation.kr</p>
        </div>
        <button type="button" @click="sendEmail">메일로 보내기</button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import DeskUploadWay from "@/components/DeskUploadWay.vue";
import MobileUploadWay from "@/components/MobileUploadWay.vue";
import DeskReferenceComplete from "@/components/DeskReferenceComplete.vue";
import MobileReferenceComplete from "@/components/MobileReferenceComplete.vue";
import Dialog from "@/components/Dialog.vue";
import client from "api-client";
import File from "@/common/file";

export default {
  name: "FestivalOrderReference",
  components: { BaseButton },
  data() {
    return {
      submitStatus: null,
      info: "",
      picture: "",
      selectFile: {
        info: null,
        infoName: "",
        picture: null,
        pictureName: "",
      },
      waitRequest: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
  },
  mounted() {
    this.submitStatus = null;
  },
  methods: {
    download(url, name, type) {
      url = `${window.location.protocol}//${window.location.hostname}/${url}`;
      File.download(url, name, type);
    },
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    async deskOnSubmit() {
      this.submitStatus = "PENDING";
      if (!this.selectFile.info && !this.selectFile.picture) {
        this.submitStatus = "ERROR";
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "파일을 선택해주세요.",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      } else {
        this.waitRequest = true;
        const params = {
          orderId: 0,
          festivalInfoFile: "",
          festivalPictrueFile: "",
        };

        if (this.selectFile.info) {
          let form = new FormData();
          form.append("uploadFile", this.selectFile.info);
          await client.fileAdd(form).then(
            (response) => {
              const { data } = response;
              if (response.status == 200) {
                this.selectFile.infoName = data.name;
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );

              return;
            }
          );
        }
        if (this.selectFile.picture) {
          let form = new FormData();
          form.append("uploadFile", this.selectFile.picture);
          await client.fileAdd(form).then(
            (response) => {
              const { data } = response;
              if (response.status == 200) {
                this.selectFile.pictureName = data.name;
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );

              return;
            }
          );
        }

        if (this.submitStatus != "ERROR") {
          params.festivalInfoFile = this.selectFile.infoName;
          params.festivalPictrueFile = this.selectFile.pictureName;

          client.referenceAdd(params).then(
            (response) => {
              this.submitStatus = "OK";
              this.waitRequest = false;
              if (response.status == 200) {
                this.$modal.show(
                  DeskReferenceComplete,
                  {},
                  {
                    width: 620,
                    height: "auto",
                  },
                  {
                    closed: () => {
                      this.$router.push({ name: "Mypage" });
                    },
                  }
                );
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
            }
          );
        }
      }
    },
    async mobileOnSubmit() {
      this.submitStatus = "PENDING";
      if (!this.selectFile.info && !this.selectFile.picture) {
        this.submitStatus = "ERROR";
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "파일을 선택해주세요.",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      } else {
        const params = {
          orderId: 0,
          festivalInfoFile: "",
          festivalPictrueFile: "",
        };

        this.waitRequest = true;
        if (this.selectFile.info) {
          let form = new FormData();
          form.append("uploadFile", this.selectFile.info);
          await client.fileAdd(form).then(
            (response) => {
              const { data } = response;
              if (response.status == 200) {
                this.selectFile.infoName = data.name;
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  closed: () => {
                    this.$router.push({ name: "Mypage" });
                  },
                }
              );

              return;
            }
          );
        }
        if (this.selectFile.picture) {
          let form = new FormData();
          form.append("uploadFile", this.selectFile.picture);
          await client.fileAdd(form).then(
            (response) => {
              const { data } = response;
              if (response.status == 200) {
                this.selectFile.pictureName = data.name;
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );

              return;
            }
          );
        }
        if (this.submitStatus != "ERROR") {
          params.festivalInfoFile = this.selectFile.infoName;
          params.festivalPictrueFile = this.selectFile.pictureName;
          client.referenceAdd(params).then(
            (response) => {
              this.submitStatus = "OK";
              this.waitRequest = false;
              if (response.status == 200) {
                this.$modal.show(
                  MobileReferenceComplete,
                  {},
                  {
                    adaptive: true,
                    width: "100%",
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
              }
            },
            (error) => {
              this.submitStatus = "ERROR";
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
            }
          );
        }
      }
    },
    previewFile(type) {
      let selectFile = null;
      if (type == "info") {
        selectFile = this.$refs.refInfo;
      } else if (type == "picture") {
        selectFile = this.$refs.refPicture;
      }
      if (0 < selectFile.files.length) {
        selectFile[type] = selectFile.files[0];
        let fileExt = selectFile[type].name.substring(
          selectFile[type].name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          !["doc", "docx", "hwp", "hwpx", "zip", "png", "jpg", "jpeg"].includes(
            fileExt
          )
        ) {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content:
                "파일 확장자는 doc, docx, hwp, hwpx, zip, png, jpg, jpeg 만 업로드 가능합니다.",
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          selectFile[type] = null;
        } else if (selectFile[type].size >= 10485760) {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "용량 10M 이하만 업로드 가능합니다.",
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          selectFile[type] = null;
        } else {
          if (type == "info") {
            this.info = selectFile[type].name;
            this.selectFile.info = selectFile[type];
          } else if (type == "picture") {
            this.picture = selectFile[type].name;
            this.selectFile.picture = selectFile[type];
          }
        }
      } else {
        this.selectFIle[type] = null;
      }
    },
    deskUploadWay() {
      this.$modal.show(
        DeskUploadWay,
        {},
        {
          width: 620,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    mobileUploadWay() {
      this.$modal.show(
        MobileUploadWay,
        {},
        {
          adaptive: true,
          width: "100%",
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
